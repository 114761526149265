@import url('https://fonts.googleapis.com/css2?family=Slackey&display=swap');

.App {
  text-align: center;
  padding: 20px;
  background-color: #222;
  color: #fff;
  min-height: 100vh;
}

.App h1 {
  margin-bottom: 20px;
  font-family: 'Slackey';
  font-weight: 500;
  font-size: 36px;
}

.App header {
  position: relative;
  margin-bottom: 20px;
}

.App header::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  height: 1px;
  background-color: #fff;
}

.App nav ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.App nav ul li {
  margin: 0 15px;
}

.App nav ul li a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  font-size: 18px;
}

.App nav ul li a.active {
  background-color: #007bff;
}

@media screen and (max-width: 600px) {
  .App h1 {
    font-size: 27px;
  }

  .App nav ul li a {
    font-size: 16px;
    padding: 8px 16px;
  }
}