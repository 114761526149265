.Data {
  text-align: center;
  padding: 20px;
  background-color: #222;
  color: #fff;
  min-height: 100vh;
}

.Data .spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Data .form-group {
  margin-bottom: 20px;
}

.Data .form-group label {
  display: block;
  margin-bottom: 10px;
}

.Data .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Data .checkbox-group label {
  margin: 5px;
}

.Data .checkbox-group label input[type='checkbox'] {
  display: none;
}

.Data .checkbox-group label span {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.Data .checkbox-group label input[type='checkbox']:checked + span {
  background-color: #007bff;
}

.Data .checkbox-group label input[type='checkbox']:disabled + span {
  cursor: default;
}

.Data .card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.Data .card {
  background-color: #333;
  border-radius: 4px;
  width: 300px;
  overflow: hidden;
  position: relative;
}

.Data .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #333;
  position: relative;
}

.Data .info-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.Data .league-name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.Data .card-content {
  padding: 20px;
  text-align: center;
}

.Data .card-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Data .card-content p {
  margin-bottom: 20px;
  font-size: 18px;
}

.Data .download-icon {
  margin-top: auto;
}

.Data .download-icon img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.Data p {
  margin: 0;
}

.Data .files {
  padding: 20px;
}

.Data .info-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Data .tooltip {
  display: none;
  position: absolute;
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  background-color: #333;
  color: #fff;
  padding: 15px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  white-space: pre-wrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  min-width: 200px;
  max-width: 300px;
  text-align: center;
  word-wrap: break-word;
  margin-top: 10px;
}

.Data .info-icon-container:hover .tooltip {
  display: block;
}

.Data .tooltip pre {
  margin: 0;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
}