.Odds {
  text-align: center;
  padding: 20px;
  background-color: #222;
  color: #fff;
  min-height: 100vh;
}

.Odds .spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Odds .matches {
  padding: 20px;
}

.Odds .game-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.Odds .divider {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 20px 0;
}

.Odds .team-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.Odds .team-logo {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Odds .team-name {
  font-weight: bold;
  text-align: center;
  white-space: normal;
}

.Odds .game-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: -20px;
}

.Odds .initial-divider {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin: 20px 0;
}

.Odds .vs-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.Odds .start-time {
  font-size: 14px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
}

.Odds .details {
  margin-top: 20px;
}

.Odds .button-group {
  display: flex;
  justify-content: center;
}

.Odds .button-group button {
  margin: 0 5px;
  padding: 10px 20px;
  background-color: #555;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.Odds .button-group button.active {
  background-color: #007bff;
}

.Odds .table {
  background-color: #333;
  margin-top: 20px;
  padding: 20px;
  border-radius: 4px;
}

.Odds .bookmaker-row {
  margin-bottom: 10px;
}

.Odds .bookmaker-name {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.Odds .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.Odds .cell {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  flex: 1;
  margin: 5px;
  padding: 10px 0px;
  background-color: #555;
  border-radius: 4px;
  position: relative;
}

.Odds .cell img {
  position: absolute;
  top: -20px;
  width: 40px;
  height: 40px;
}

.Odds .cell img[src="/icons/Crown_left.png"] {
  left: -20px;
}

.Odds .cell img[src="/icons/Crown_right.png"] {
  right: -20px;
}

.Odds .line-container {
  display: flex;
  flex: 1;
  margin: 5px;
}

.Odds .line-cell {
  text-align: center;
  font-size: 14px;
  flex: 1;
  font-weight: bold;
  padding: 10px;
  background-color: #555;
  border-radius: 4px;
}

.Odds .line-cell:first-child {
  margin-right: 10px;
}

.Odds .form-group {
  padding: -20px;
  margin-bottom: 20px;
}

.Odds .form-group label {
  display: block;
  margin-bottom: 10px;
}

.Odds .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Odds .checkbox-group label {
  margin: 5px;
}

.Odds .checkbox-group label input[type='checkbox'] {
  display: none;
}

.Odds .checkbox-group label span {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.Odds .checkbox-group label input[type='checkbox']:checked + span {
  background-color: #007bff;
}

.Odds .league-buttons {
  display: flex;
  justify-content: center;
}

.Odds .league-buttons button {
  margin: 0 5px;
  padding: 10px 20px;
  background-color: #555;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.Odds .league-buttons button.active {
  background-color: #007bff;
}

.Odds p {
  margin: 0;
}

.Odds .checkbox-group label input[type='checkbox']:disabled + span {
  cursor: default;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.live-text {
  color: red;
  font-weight: bold;
  animation: flash 1s infinite;
}

@media screen and (max-width: 750px) {
  .Odds .team-name,
  .Odds .vs-text,
  .Odds .start-time {
    font-size: 12px;
  }
}