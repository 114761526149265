@import url('https://fonts.googleapis.com/css2?family=Slackey&display=swap');

.MajorLeague {
  text-align: center;
  padding: 20px;
  background-color: #222;
  color: #fff;
  min-height: 100vh;
}

.MajorLeague h1 {
  margin-bottom: 20px;
  font-family: 'Slackey';
  font-weight: 500;
}

.MajorLeague h2 {
  font-family: 'Slackey';
  font-weight: 500;
  margin: 15px;
}

.MajorLeague form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MajorLeague .form-group {
  margin-bottom: 20px;
}

.MajorLeague label {
  display: block;
  margin-bottom: 10px;
}

.MajorLeague .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.MajorLeague .checkbox-group label {
  margin: 5px;
}

.MajorLeague .checkbox-group label input[type='checkbox'] {
  display: none;
}

.MajorLeague .checkbox-group label span {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.MajorLeague .checkbox-group label input[type='checkbox']:checked + span {
  background-color: #007bff;
}

.MajorLeague .button-group {
  display: flex;
  justify-content: center;
}

.MajorLeague .button-group button {
  margin-top: 5px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.MajorLeague .button-group button.active {
  background-color: #007bff;
}

.MajorLeague button:disabled {
  background-color: #777;
  cursor: default;
}

.MajorLeague .spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.MajorLeague .results-section {
  background-color: #333;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
}

.MajorLeague .match-prediction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: relative;
}

.MajorLeague .match-prediction::before,
.MajorLeague .match-prediction::after {
  content: "";
  position: absolute;
  top: 25px;
  bottom: 25px;
  width: 1px;
  background-color: #ddd;
}

.MajorLeague .match-prediction::before {
  left: 33.33%;
}

.MajorLeague .match-prediction::after {
  right: 33.33%;
}

.MajorLeague .team-prediction img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.MajorLeague .team-prediction,
.MajorLeague .total-predictions {
  text-align: center;
  flex: 1;
}

.MajorLeague .total-predictions {
  padding: 0 20px;
}

.MajorLeague .team-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}

.MajorLeague .total-value {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.MajorLeague .team-header p,
.MajorLeague .team-prediction p {
  margin: 2px 0;
}

.MajorLeague .team-prediction p span,
.MajorLeague .total-predictions p span {
  font-weight: bold;
}

.MajorLeague .over-under {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MajorLeague .over-under p {
  margin: 0 5px;
}

.MajorLeague .positive-ev {
  color: #4caf50;
}

.MajorLeague .negative-ev {
  color: #f44336;
}

.MajorLeague .column-headers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 24px;
}

.MajorLeague .column-headers p {
  flex: 1;
  text-align: center;
  margin: 5px;
}

.MajorLeague .form-group select {
  width: 200px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  appearance: none;
  background-color: #333;
  color: #fff;
  text-align: center;
  text-align-last: center;
  cursor: pointer;
  margin: 5px;
}

.MajorLeague .form-group select:focus {
  outline: none;
}

.MajorLeague .form-group select option {
  color: #fff;
  background-color: #333;
}

.MajorLeague .form-group select.selected {
  background-color: #007bff;
}

.MajorLeague .commence-time {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
}

.MajorLeague p {
  margin: 0;
}

.MajorLeague .predictions {
  padding: 20px
}

@media (max-width: 750px) {
  .MajorLeague .match-prediction {
    font-size: 10px;
    padding: 3px;
  }

  .MajorLeague .team-prediction img {
    width: 30px;
    height: 30px;
    margin-bottom: 2px;
  }

  .MajorLeague .team-header {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .MajorLeague .total-value {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .MajorLeague .team-prediction p,
  .MajorLeague .total-predictions p {
    margin: 1px 0;
    font-size: 10px;
  }

  .MajorLeague .column-headers {
    font-size: 16px;
    margin-bottom: 3px;
  }

  .MajorLeague .commence-time {
    font-size: 10px;
    margin-bottom: 3px;
  }

  .MajorLeague .over-under p {
    font-size: 10px;
  }

  .MajorLeague .match-prediction::before,
  .MajorLeague .match-prediction::after {
    top: 20px;
    bottom: 20px;
  }
}