@import url('https://fonts.googleapis.com/css2?family=Slackey&display=swap');

.Horses {
  text-align: center;
  padding: 20px;
  background-color: #222;
  color: #fff;
  min-height: 100vh;
}

.Horses h1 {
  margin-bottom: 20px;
  font-family: 'Slackey';
  font-weight: 500;
}

.Horses h2 {
  font-family: 'Slackey';
  font-weight: 500;
  margin: 20px;
}

.Horses form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Horses .form-group {
  margin-bottom: 20px;
}

.Horses label {
  display: block;
  margin-bottom: 10px;
}

.Horses .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Horses .checkbox-group label {
  margin: 5px;
}

.Horses .checkbox-group label input[type='checkbox'] {
  display: none;
}

.Horses .checkbox-group label span {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.Horses .checkbox-group label input[type='checkbox']:checked + span {
  background-color: #007bff;
}

.Horses .button-group {
  display: flex;
  justify-content: center;
}

.Horses .button-group button {
  margin-top: 5px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.Horses .button-group button.active {
  background-color: #007bff;
}

.Horses button:disabled {
  background-color: #777;
  cursor: default;
}

.Horses .spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Horses .results-section {
  background-color: #333;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
}

.Horses .race-prediction {
  margin-bottom: 20px;
}

.Horses .race-prediction h3 {
  margin: 10px;
}

.Horses .race-prediction table {
  width: 100%;
  border-collapse: collapse;
}

.Horses .race-prediction th,
.Horses .race-prediction td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.Horses .race-prediction th:last-child,
.Horses .race-prediction td:last-child {
  text-align: center;
}

.Horses .race-prediction td.centered {
  text-align: center;
}

.Horses p {
  margin: 0;
}

.Horses .predictions {
  padding: 20px
}

@media screen and (max-width: 600px) {
    .Horses .race-prediction h3 {
      font-size: 18px;
    }
    .Horses .race-prediction th,
    .Horses .race-prediction td {
      font-size: 14px;
      padding: 6px;
    }
}