@import url('https://fonts.googleapis.com/css2?family=Slackey&display=swap');

.Models {
  text-align: center;
  padding: 20px;
  background-color: #222;
  color: #fff;
  min-height: 100vh;
}

.Models h1 {
  margin-bottom: 20px;
  font-family: 'Slackey';
  font-weight: 500;
}

.Models h2 {
  font-family: 'Slackey';
  font-weight: 500;
  margin: 15px;
}

.Models form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Models .form-group {
  margin-bottom: 20px;
}

.Models label {
  display: block;
  margin-bottom: 10px;
}

.Models .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Models .checkbox-group label {
  margin: 5px;
}

.Models .checkbox-group label input[type='checkbox'] {
  display: none;
}

.Models .checkbox-group label span {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.Models .checkbox-group label input[type='checkbox']:checked + span {
  background-color: #007bff;
}

.Models .checkbox-group label span.horses-label {
  display: inline-block;
  padding: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.Models .checkbox-group label span.horses-label img {
  width: auto;
  height: 30px;
  vertical-align: middle;
}